import React from "react"
import * as styles from './style.module.sass'

import MemberName from "../../atoms/MemberName"
import MemberRoll from "../../atoms/MemberRoll"
import MemberMainText from "../../atoms/MemberMainText"

const MemberBodyText = (props) => (
  <>
    <div className={styles.MemberBodyTextTitle}>
      <MemberName
        layoutName={props.layoutName}
        name={props.post.title}
        enName={props.post.MemberProfile.memberEnName}
      />
    </div>
    <div className={styles.MemberBodyTextRoll}>
      <MemberRoll
        layoutName={props.layoutName}
        roll={props.post.MemberProfile.memberRoll}
        enRoll={props.post.MemberProfile.memberRollEn}
      />
    </div>
    {
      props.post.MemberProfile.memberProfile && (
        <div className={styles.MemberBodyTextProfile}>
          <MemberMainText
            layoutName={props.layoutName}
            text={props.post.MemberProfile.memberProfile}
          />
        </div>
      )
    }
    {
      props.post.MemberProfile.memberCareer && (
        <div>
          <MemberMainText
            layoutName={props.layoutName}
            text={props.post.MemberProfile.memberCareer}
          />
        </div>
      )
    }
  </>
);

export default MemberBodyText

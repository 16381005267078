import React, {useRef, useEffect} from "react"
import { memo } from "react"
import * as styles from './style.module.sass'

const AboutNomenaBaseFloor = memo((props) => {
  const isMobile = useRef(
    typeof window === 'undefined'? false :
    (/android|ipod|ipad|iphone|macintosh/.test(navigator.userAgent.toLowerCase()) && 'ontouchend' in document)?true : false
  )

  const onMouse = () => {
    props.hover();
  }

  const leaveMouse = () => {
    props.release();
  }

  return(
    <div
      className={
        props.hoveredNum==-1 || props.hoveredNum==props.index? styles.AboutNomenaBaseFloor
        : styles.AboutNomenaBaseFloor + ' ' + styles.AboutNomenaBaseFloorHide
      }
      onMouseEnter={() => {if(!isMobile.current) onMouse()}}
      onMouseLeave={() => {if(!isMobile.current) leaveMouse()}}
      onTouchStart={() => {
        if(isMobile.current && props.hoveredNum == props.index) leaveMouse();
        else onMouse();
      }}
    >
      <span>{props.floorName}</span>
      <span>/</span>
      <span>{props.floorUse}</span>
    </div>
  )
});

export default AboutNomenaBaseFloor

import React, {useState} from "react"
import Modal from "react-modal";
import {navigate} from 'gatsby';
import * as styles from './style.module.sass'

import MemberItem from '../../molecules/MemberItem'
import MemberTemplate from '../../template/MemberTemplate'

// react-modalの初期設定
// root nodeのSelectorを設定
Modal.setAppElement("#___gatsby")

const MemberLIst = (props) => {
  const [modalIsOpen, setIsOpen] = useState(false);

  const customStyles = {
    overlay: {
      zIndex: '999',
      position: `fixed`,
      top: '0',
      left: '0',
      width: '100%',
      height: 'calc(var(--vh, 1vh) * 100)',
      backgroundColor: `rgb(255, 255, 255)`
    },
    content: {
      position: `absolute`,
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      border: 'none',
      borderRadius: '0px',
      padding: '0',
    },
  }

  const handleOpenModal = (num, slug) => {
    setIsOpen(num);
  }

  const handleCloseModal = () => {
    setIsOpen(false);
  }

  return(
    <>
      <ol className={styles.MemberList}>
        {props.memberList.map((member, id) => (
          <li
            key={id}
            className={styles.MemberItem}
          >
            <MemberItem
              layoutName={props.layoutName}
              image={member.node.MemberProfile.memberImage}
              imageHover={member.node.MemberProfile.memberImageHover}
              name={member.node.title}
              slug={member.node.slug}
              clickEvent={() => {handleOpenModal(id, member.node.slug)}}
            />
            <span style={{display:"none"}}>
              <span>{member.node.MemberProfile.memberEnName}</span>
              <span>{member.node.MemberProfile.memberRoll}</span>
              <span>{member.node.MemberProfile.memberRollEn}</span>
              <span>{member.node.MemberProfile.memberProfile}</span>
              <span>{member.node.MemberProfile.memberCareer}</span>
            </span>
          </li>
        ))}
      </ol>
      {props.memberList.map((member, id) => (
        <Modal
          key={id}
          isOpen={modalIsOpen===id? true : false}
          onRequestClose={() => handleCloseModal()}
          shouldCloseOnEsc={true}
          style={customStyles}
          overlayClassName={{
            base: "overlay",
            afterOpen: "overlayAfter",
            beforeClose: "overlayBefore"
          }}
        >
          <MemberTemplate
            layoutName={props.layoutName}
            modalOpenMethod={(_id, _slug) => {handleOpenModal(_id, _slug)}}
            modalCloseMethod={() => handleCloseModal()}
            post={member.node}
            previous={member.previous}
            next={member.next}
            all={props.memberList}
          />
        </Modal>
      ))}
    </>
  )
}

export default MemberLIst

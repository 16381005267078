import React from "react"
import * as styles from './style.module.sass'

import AwardSingle from '../../atoms/AwardSingle'

const AwardsList = (props) => {
  return(
    <ul className={styles.AboutList}>
      {props.awardsList.map((award, index) => {
        return(
          <li
            className={styles.AboutListItem}
            key={index}
          >
            <AwardSingle
              layoutName={props.layoutName}
              year={award.year}
              awardName={award.awardName}
            />
          </li>
        )
      })}
    </ul>
  )
};

export default AwardsList

import React, { useEffect, useRef } from "react"
import { memo } from "react"
import * as styles from './style.module.sass'

import AboutNomenaBaseSwitchImage from "../AboutNomenaBaseSwitchImage"

const AboutNomenaBaseImage= memo((props) => {
  const containerElem = useRef(null),
  moveElem = useRef(null),
  containerInfo = useRef(null),
  ticking = useRef(false),
  tickingMouse = useRef(false);

  const imageArray = [
    props.information.aboutBaseRoof.photos,
    props.information.aboutBase4f.photos,
    props.information.aboutBase3f.photos,
    props.information.aboutBase2f.photos,
    props.information.aboutBase1f.photos,
  ];

  const resetContainerPos = () => {
    if(containerElem.current==null || typeof window === 'undefined') return;
    if( ticking.current==false ) {
      window.requestAnimationFrame( () => {
        if(containerElem.current==null || typeof window === 'undefined') return;
        ticking.current = false;
        const rect = containerElem.current.getBoundingClientRect();
        containerInfo.current = {
          sx: rect.left,
          sy: rect.top,
          ex: rect.left + rect.width,
          ey: rect.top + rect.height
        }
      });
      ticking.current = true;
    }
  }

  const followFunc = (_e) => {
    if( tickingMouse.current==false ) {
      window.requestAnimationFrame( () => {
        tickingMouse.current = false;
        const posX = _e.clientX, posY = _e.clientY;
        if(moveElem.current == null || containerInfo.current==null) return;
        if(containerInfo.current.sx>posX || containerInfo.current.ex<posX || containerInfo.current.sy>posY || containerInfo.current.ey<posY ) return;
        moveElem.current.style.transform = 'translate(' + posX+ 'px, ' + posY + 'px)';
      });
      tickingMouse.current = true;
    }
  }

  useEffect(() => {
    if(containerElem.current==null || typeof window === 'undefined') return;
    document.addEventListener(`mousemove`, followFunc, {passive: true});
    return () => document.removeEventListener(`mousemove`, followFunc)
  })

  useEffect(() => {
    if(typeof window === 'undefined') return;
    window.addEventListener(`resize`, resetContainerPos);
    return () => window.removeEventListener(`resize`, resetContainerPos)
  })

  useEffect(() => {
    if(typeof window === 'undefined') return;
    window.addEventListener(`scroll`, resetContainerPos, { passive: true });
    return () => window.removeEventListener(`scroll`, resetContainerPos, { passive: true })
  })

  useEffect(() => {
    resetContainerPos();
  }, [])

  return(
    <div
      ref={containerElem}
      className={styles.AboutNomenaBaseImage}>
      <div
        ref={moveElem}
        className={styles.AboutNomenaBaseImageContainer}
      >
        {imageArray.map((images, index) =>
          <div
            key={index}
            className={
              props.hoveredNum==index? styles.AboutNomenaBaseImageWrap + ' ' + styles.AboutNomenaBaseImageWrapShow
              : styles.AboutNomenaBaseImageWrap
            }
          >
            <AboutNomenaBaseSwitchImage
              layoutName={props.layoutName}
              source={images}
              active={props.hoveredNum==index}
            />
          </div>
        )}
      </div>
    </div>
  )
});

export default AboutNomenaBaseImage

import React, {useEffect, useRef, useState} from "react"
import * as styles from './style.module.sass'

import MemberLinkItem from "../../atoms/MemberLinkItem";

const MemberLinkList = (props) => {
  const memberList = props.all;

  const linkListDom = useRef(null),
  previousPost = useRef(props.previous==null? memberList[memberList.length-1].node : props.previous),
  nextPost = useRef(props.next==null? memberList[0].node : props.next),
  isPrevNextNull = useRef((props.previous==null || props.next==null)? true : false),
  ticking = useRef(false),
  viewWidth = useRef(null),
  itemAllWidth = useRef(-1);
  const isMobile = useRef(
    typeof window === 'undefined'? false :
    (/android|ipod|ipad|iphone|macintosh/.test(navigator.userAgent.toLowerCase()) && 'ontouchend' in document)?true : false
  );

  const [wrapClassName, setClassName] = useState(styles.MemberLinkList);

  const getItemClassName = (_slug) => {
    let baseClassName = styles.MemberLinkListItem;
    if(_slug == props.post.slug) baseClassName += ' ' + styles.MemberLinkListItemIsSelected;
    if(_slug == previousPost.current.slug || _slug == nextPost.current.slug) baseClassName += ' ' + styles.MemberLinkListItemOmit;
    return baseClassName
  }

  const setOmit = () => {
    if(isPrevNextNull.current) setClassName(styles.MemberLinkList + ' ' + styles.MemberLinkListOmit + ' ' + styles.MemberLinkListOmitRev + ' ' + styles.MemberLinkListIsActive);
    else {
      setClassName(styles.MemberLinkList + ' ' + styles.MemberLinkListOmit + ' ' + styles.MemberLinkListIsActive);
    }
  }

  const removeOmit = () => {
    setClassName(styles.MemberLinkList + ' ' + styles.MemberLinkListIsActive);
  }

  const setListClassName = () => {
    if(linkListDom.current.classList.contains(styles.MemberLinkListIsActive)) return false;
    const wrapWidth = linkListDom.current.clientWidth,
    item = linkListDom.current.getElementsByTagName('li');
    let itemWidth = 0;
    for(let i = 0; i < item.length; i++) {
      itemWidth += item[i].clientWidth;
      if(i == item.length-1) {
        itemAllWidth.current = itemWidth;
        if(wrapWidth < itemAllWidth.current) setOmit();
        else{ removeOmit(); }
      }
    }
  }

  const resetListClassName = () => {
    if(linkListDom.current==null || itemAllWidth.current==-1 || viewWidth.current==null) return;
    if( ticking.current==false && viewWidth.current!=window.innerWidth) {
      window.requestAnimationFrame( () => {
        ticking.current = false;
        viewWidth.current = window.innerWidth;
        const wrapWidth = linkListDom.current.clientWidth;
        if(wrapWidth < itemAllWidth.current) setOmit();
        else{ removeOmit(); }
      });
      ticking.current = true;
    }
  }

  useEffect(() => {
    if(isMobile.current) setOmit();
    else{
      setListClassName();
      viewWidth.current = window.innerWidth;
    }
  }, []);

  useEffect(() => {
    if(isMobile.current) return false;
    window.addEventListener(`resize`, resetListClassName);
    return () => window.removeEventListener(`resize`, resetListClassName)
  })

  return(
    <div
      className={styles.MemberLinkListWrap}
    >
      <ul
        className={wrapClassName}
        ref={linkListDom}
      >
        {memberList.map((member, index) => {
          return(
            <li
              className={getItemClassName(member.node.slug)}
              key={index}
            >
              <MemberLinkItem
                layoutName={props.layoutName}
                slug={member.node.slug}
                name={member.node.title}
                id={index}
                modalOpenMethod={(_id, _slug) => {props.modalOpenMethod(_id, _slug)}}
              />
            </li>
          )
        })}
      </ul>
    </div>
  )
};

export default MemberLinkList

import React, {useEffect, useLayoutEffect, useRef} from "react"
import * as styles from './style.module.sass'
import { memo } from "react"
import { useLocation } from "@reach/router"

import FvFooterNews from "../../atoms/FvFooterNews"
import LogoItem from "../../atoms/LogoItem"

const FvFooter = memo((props) => {
  const isMobile = useRef(
    typeof window === 'undefined'? false :
    (/android|ipod|ipad|iphone|macintosh/.test(navigator.userAgent.toLowerCase()) && 'ontouchend' in document)? true : false
  ),
  ticking = useRef(false),
  disabled = useRef(true),
  hide = useRef(false),
  newsElem = useRef(null),
  logoElem = useRef(null),
  bgElem = useRef(null),
  containerDom = useRef(typeof window === 'undefined'? null : document.getElementById('container')),
  scrollTarget = useRef(typeof window === 'undefined'? null : props.layoutName=='/'&&containerDom.current!=undefined&&isMobile.current? containerDom.current : window),
  documentTarget = useRef(typeof window === 'undefined'? null : props.layoutName=='/'&&containerDom.current!=undefined&&isMobile.current? containerDom.current : document.documentElement);

  const updateStatus = (_scrollTop) => {
    if(disabled.current) {
      disabled.current = false;
      if(newsElem.current!=null) newsElem.current.classList.remove(styles.FvFooterNewsIsDisable);
      if(bgElem.current!=null) bgElem.current.classList.remove(styles.FvFooterBackgroundIsHide);
      if(logoElem.current!=null) logoElem.current.classList.remove(styles.FvFooterLogoIsDisable);
    }

    if(_scrollTop < 10) {
      if(newsElem.current!=null&&newsElem.current.classList.contains(styles.FvFooterNewsIsHide)) newsElem.current.classList.remove(styles.FvFooterNewsIsHide);
      if(bgElem.current!=null&&bgElem.current.classList.contains(styles.FvFooterBackgroundIsHide)) bgElem.current.classList.remove(styles.FvFooterBackgroundIsHide);
      if(logoElem.current!=null&&logoElem.current.classList.contains(styles.FvFooterLogoIsHide)) logoElem.current.classList.remove(styles.FvFooterLogoIsHide);
    }else if(_scrollTop >= 10){
      if(newsElem.current!=null&&!newsElem.current.classList.contains(styles.FvFooterNewsIsHide)) newsElem.current.classList.add(styles.FvFooterNewsIsHide);
      if(bgElem.current!=null&&!bgElem.current.classList.contains(styles.FvFooterBackgroundIsHide)) bgElem.current.classList.add(styles.FvFooterBackgroundIsHide);
      if(logoElem.current!=null&&!logoElem.current.classList.contains(styles.FvFooterLogoIsHide)) logoElem.current.classList.add(styles.FvFooterLogoIsHide);
    }
  }

  const scrollEventTicking = () => {
    if( !ticking.current ) {
      window.requestAnimationFrame( () => {
        const scrollTop = scrollTarget.current.pageYOffset || documentTarget.current.scrollTop;
        updateStatus(scrollTop);
        ticking.current = false;
      });
      ticking.current = true;
    }
  }

  useLayoutEffect(() => {
    if(typeof window === 'undefined') return;
    containerDom.current = document.getElementById('container');
    scrollTarget.current = typeof window === 'undefined'? null : props.layoutName=='/'&&containerDom.current!=undefined&&isMobile.current? containerDom.current : window;
    documentTarget.current = typeof window === 'undefined'? null : props.layoutName=='/'&&containerDom.current!=undefined&&isMobile.current? containerDom.current : document.documentElement;
    if(scrollTarget.current == undefined) return;
    scrollEventTicking();
  }, [props.newsList, props.layoutName])

  useLayoutEffect(() => {
    if(typeof window === 'undefined' || props.layoutName=='/projects/') return;
    if(scrollTarget.current == null) return;
    ticking.current = false;
    scrollTarget.current.addEventListener(`scroll`, scrollEventTicking, { passive: true });
    return () => {
      ticking.current = false;
      scrollTarget.current.removeEventListener(`scroll`, scrollEventTicking, { passive: true })
    }
  }, [props.layoutName, scrollTarget.current])

  return(
    <>
      {props.layoutName=='/' && (
        <>
          <div
            className={
              styles.FvFooterNews + ' ' + styles.FvFooterNewsIsDisable
            }
            ref={newsElem}
          >
            <FvFooterNews
              layoutName={props.layoutName}
              newsList={props.newsList}
            />
          </div>
        </>
      )}
      {(props.layoutName=='/about/' || props.layoutName=='/projects/') && (
        <div
          id="fv-footer"
          className={
            props.layoutName!='/projects/'? styles.FvFooterBackground : styles.FvFooterBackground + ' ' + styles.FvFooterBackgroundShow
          }
          ref={bgElem}
        ></div>
      )}
      <div
        className={
          props.layoutName != '/projects/'? styles.FvFooterLogo + ' ' + styles.FvFooterLogoIsDisable : styles.FvFooterLogo + ' ' + styles.FvFooterLogoShow
        }
        ref={logoElem}
      >
        <LogoItem
        />
      </div>
    </>
  )
});

export default FvFooter

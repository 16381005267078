import React from "react"
import * as styles from './style.module.sass'

import BackButton from "../../atoms/BackButton"
import MemberLinkList from "../../molecules/MemberLinkList"
import MemberBody from "../../organisms/MemberBody"

const MemberTemplate = (props) => {
  return (
    <div className={styles.member}>
      <div className={styles.memberBackButton}>
        <BackButton
          modalCloseMethod={() => props.modalCloseMethod()}
        />
      </div>
      <div className={styles.memberBody}>
        <MemberBody post={props.post} />
      </div>
      <div className={styles.memberLinkList}>
        <MemberLinkList
          post={props.post}
          previous={props.previous}
          next={props.next}
          all={props.all}
          modalOpenMethod={(_id, _slug) => {props.modalOpenMethod(_id, _slug)}}
        />
      </div>
    </div>
  )
}

export default MemberTemplate

import React from "react"
import * as styles from './style.module.sass'

const SectionTitle = (props) => (
  <h2 className={styles.SectionTitle}>
    {props.title}
  </h2>
)

export default SectionTitle

import React, {useRef} from "react"
import * as styles from './style.module.sass'

import SpPageTitle from "../../atoms/SpPageTitle"
import SectionTitle from "../../atoms/SectionTItle"
import AboutIntroduction from '../../molecules/AboutIntroduction'
import MemberLIst from "../../organisms/MemberList"
import InternshipList from "../../organisms/InternshipList"
import AboutNomenBase from "../../organisms/AboutNomenaBase"
import ContactBody from "../../organisms/ContactBody"
import AwardsList from "../../molecules/AwardsList"
import FvFooter from "../../organisms/FvFooter"
import Footer from "../../organisms/Footer"

const AboutTemplate = (props) => {
  const layoutName = useRef('/about/');
  const scrollTarget = useRef(null);

  return (
    <>
      <div
        className={styles.container}
        id={"container"}
      >
        <div
          className={styles.containerWrap}
        >
          <SpPageTitle
            layoutName={layoutName.current}
            title={"About"}
          />
          <article>
            <section className={styles.aboutIntroduction}>
              <AboutIntroduction
                layoutName={layoutName.current}
                introduction={props.data.aboutQuery.aboutIntroduction}
              />
            </section>

            <section className={styles.aboutMemberList}>
              <SectionTitle
                layoutName={layoutName.current}
                title={'Member'}
              />
              <MemberLIst
                layoutName={layoutName.current}
                memberList={props.data.memberQuery.edges}
              />
            </section>

            <section className={styles.aboutInternship}>
              <div className={styles.aboutInternshipTitle}>
                <SectionTitle
                  layoutName={layoutName.current}
                  title={'Internship'}
                />
              </div>
              <div className={styles.aboutInternshipContent}>
                <InternshipList
                  layoutName={layoutName.current}
                  internshipList={props.data.internshipQuery.nodes}
                />
              </div>
            </section>

            <section className={styles.aboutNomenaBase}>
              <SectionTitle
                layoutName={layoutName.current}
                title={'nomena base'}
              />
              <AboutNomenBase
                layoutName={layoutName.current}
                information={props.data.aboutQuery.aboutBase}
              />
            </section>

            <section className={styles.aboutCompanyProfile}>
              <div className={styles.aboutCompanyProfileTitle}>
                <SectionTitle
                  layoutName={layoutName.current}
                  title={'Company Profile'}
                />
              </div>
              <div className={styles.aboutCompanyProfileContent}>
                <ContactBody
                  layoutName={layoutName.current}
                  contactInformation={props.data.optionQuery.edges[0].node.themeOptions.contactInformation}
                  isAbout={true}
                />
              </div>
            </section>

            <section className={styles.aboutAwards}>
              <div className={styles.aboutAwardsTitle}>
                <SectionTitle
                  layoutName={layoutName.current}
                  title={'Awards'}
                />
              </div>
              <div className={styles.aboutAwardsContent}>
                <AwardsList
                  layoutName={layoutName.current}
                  awardsList={props.data.aboutQuery.aboutAward.aboutAwardlist}
                />
              </div>
            </section>
          </article>
          <Footer
            layoutName={layoutName.current}
          />
        </div>
      </div>
      <FvFooter
        layoutName={layoutName.current}
        scrollTarget={scrollTarget.current} />
    </>
  )
}

export default AboutTemplate

import React from "react"
import * as styles from './style.module.sass'

import ContactInformationItem from '../../atoms/ContactInformationItem'

const ContactInformationList = (props) => {
  let dataList = [
    {section: "Tel", isMail: false, isTel: true, content: props.contactInformation.commonPhoneNum},
    {section: "Fax", isMail: false, isTel: false, content: props.contactInformation.commonFaxNumber},
    {section: "Email", isMail: true, isTel: false, content: props.contactInformation.commonEmailAddress}
  ];

  if(props.isAbout) {
    const addList = [
      {section: "Founder", isMail: false, isTel: false, content: props.contactInformation.commonFounder},
      {section: "Founded", isMail: false, isTel: false, content: props.contactInformation.commonFoundDate}
    ]
    dataList = dataList.concat(addList);
  }

  return(
    <ul className={styles.ContactInformationList}>
      {dataList.map((dataItem, index) => {
        if(dataItem.content != null) {
          return(
            <li
              className={styles.ContactInformationItem}
              key={index}
            >
              <ContactInformationItem
                layoutName={props.layoutName}
                section={dataItem.section}
                isMail={dataItem.isMail}
                isTel={dataItem.isTel}
                content={dataItem.content}
                isAbout={props.isAbout}
              />
            </li>
          )
        }
      })}
    </ul>
  )
};

export default ContactInformationList

import React from "react"
import { Link } from "gatsby"
import * as styles from './style.module.sass'

import HoverProvider from '../HoverProvider'

const FvFooterNews = (props) => {
  const data = props.newsList!=null? props.newsList[0].node : "",
  date = data.date,
  title = data.title;

  return(
    <HoverProvider
      hoverClassName={styles.isHover}
    >
      <Link
        to={`/news/${data.slug}`}
        className={styles.FvFooterNews}
      >
        <span className={styles.FvFooterNewsDate}>{date}</span>
        <span><span>{title}</span></span>
      </Link>
    </HoverProvider>
  )
}

export default FvFooterNews

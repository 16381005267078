import React from "react"
import * as styles from './style.module.sass'

const InternshipList = (props) => {
  return(
    <ul className={styles.internshipList}>
        {props.internshipList.map((internship, id) => (
            <li
                key={id}
                className={styles.internshipItem}
            >
                <p
                    className={styles.internshipItemDate}
                >
                    {internship.internshipContent.startTime}
                </p>
                <div
                    className={styles.internshipItemContent}
                >
                    <h3 className={styles.internshipItemName}>{internship.title}</h3>
                    {
                        internship.internshipContent.rollLis.length > 0 && (
                            <ul className={styles.internshipItemRollList}>
                                {internship.internshipContent.rollLis.map((roll, id2) => (
                                    <li key={id2}>{roll.roll}</li>
                                ))}
                            </ul>
                        )
                    }
                </div>
            </li>
        ))}
    </ul>
  )
}

export default InternshipList

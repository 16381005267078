import React from 'react'
import { Link } from "gatsby"
import * as styles from './style.module.sass'

import HoverProvider from '../HoverProvider'

const MemberLinkItem = (props) => {
  return(
    <HoverProvider
      hoverClassName={styles.isHover}
    >
      <button
        onClick={() => {props.modalOpenMethod(props.id, props.slug)}}
        className={styles.MemberLinkItem}
      >
        {props.name}
      </button>
    </HoverProvider>
  )
}

export default MemberLinkItem

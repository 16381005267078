import React, {useCallback} from "react"
import * as styles from './style.module.sass'

import AboutNomenaBaseFloor from "../../atoms/AboutNomenaBaseFloor"

const AboutNomenaBaseFloorList = (props) => {
  const baseArray = [
    {floor: 'R', usage: props.information.aboutBaseRoof.use},
    {floor: '4F', usage: props.information.aboutBase4f.use},
    {floor: '3F', usage: props.information.aboutBase3f.use},
    {floor: '2F', usage: props.information.aboutBase2f.use},
    {floor: '1F', usage: props.information.aboutBase1f.use},
  ]

  return(
    <ol className={styles.AboutNomenaBaseFloorList}>
      {baseArray.map((base, index) =>
          <li
            key={index}
            className={styles.AboutNomenaBaseFloorListItem}
          >
            <AboutNomenaBaseFloor
              layoutName={props.layoutName}
              floorUse={base.usage}
              floorName={base.floor}
              floorImage={base.image}
              index={index}
              hoveredNum={props.hoveredNum}
              hover={() => props.updateHoveredNum(index)}
              release={() => props.updateHoveredNum(-1)}
            />
          </li>
      )}
    </ol>
  )
}

export default AboutNomenaBaseFloorList

import React from "react"
import * as styles from './style.module.sass'

const ContactPlainText = (props) => (
  <p className={styles.ContactPlainText}>
    {props.text}
  </p>
)

export default ContactPlainText

import React, {useRef} from "react"
import { memo } from "react"
import * as styles from './style.module.sass'

import GraphSvg from '../../../images/office-graph.svg'
import AboutNomenaBaseSwitchImage from "../AboutNomenaBaseSwitchImage"

const AboutNomenaBaseGraph = memo((props) => {
  const hoveringNum = useRef(-1);
  const isMobile = useRef(
    typeof window === 'undefined'? false :
    (/android|ipod|ipad|iphone|macintosh/.test(navigator.userAgent.toLowerCase()) && 'ontouchend' in document)?true : false
  )
  const array = [1, 2, 3, 4, 5];

  const imageArray = [
    props.information.aboutBaseRoof.photos,
    props.information.aboutBase4f.photos,
    props.information.aboutBase3f.photos,
    props.information.aboutBase2f.photos,
    props.information.aboutBase1f.photos,
  ];

  hoveringNum.current = props.hoveredNum;

  const onMouse = (_index) => {
    props.updateHoveredNum(_index);
    hoveringNum.current= _index;
  }

  const leaveMouse = () => {
    props.updateHoveredNum(-1);
    hoveringNum.current= -1;
  }

  return(
    <div className={styles.AboutNomenaBaseGraph} >
      <img className={styles.AboutNomenaBaseGraphBody} src={GraphSvg} />
      <div className={styles.AboutNomenaBaseGraphHoverAreaWrap}>
        {array.map((val, index) =>
          <div
            key={index}
            className={
              hoveringNum.current==index?  styles.AboutNomenaBaseGraphHoverArea + ' ' + styles.AboutNomenaBaseGraphHoverAreaIsHover
              : styles.AboutNomenaBaseGraphHoverArea
            }
            onMouseEnter={() => {if(!isMobile.current) onMouse(index)}}
            onMouseLeave={() => {if(!isMobile.current) leaveMouse()}}
            onTouchStart={() => {
              if(isMobile.current && hoveringNum.current == index) leaveMouse();
              else onMouse(index);
            }}
          >
            {isMobile.current && (
              <div className={
                props.hoveredNum==index? styles.spBaseImage + ' ' + styles.spBaseImageIsShow : styles.spBaseImage
              }>
                <AboutNomenaBaseSwitchImage
                  layoutName={props.layoutName}
                  source={imageArray[index]}
                  active={props.hoveredNum==index}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
  
});

export default AboutNomenaBaseGraph

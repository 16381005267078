import React from "react"
import downloadIconSvgSP from '../../../images/download-icon-sp.svg'
import downloadIconSvgPC from '../../../images/download-icon-pc.svg'
import * as styles from './style.module.sass'

import HoverProvider from '../HoverProvider'

const DownloadFile = (props) => {
  return(
    <HoverProvider
      hoverClassName={styles.DownloadFileIsHover}
    >
      <a
        href={props.fileUrl}
        className={styles.DownloadFile}
        download
      >
        <span>PDF Download</span>
        <span>
          <picture>
            <source srcSet={downloadIconSvgSP} media="(max-width: 768px)" />
            <img srcSet={downloadIconSvgPC} />
          </picture>
        </span>
      </a>
    </HoverProvider>
  )
}

export default DownloadFile

import React from 'react'
import HTMLReactParser from 'html-react-parser'
import * as styles from './style.module.sass'

import HoverProvider from '../HoverProvider'

const MemberMaintext = (props) => {
  const options = {
    replace: (node) => {
      if (
        node.name === 'a' &&
        node.attribs &&
        node.attribs.href
      ) {
        return (
          <HoverProvider
            hoverClassName={styles.MemberProfileLinkIsHover}
          >
            <a
              href={node.attribs.href}
              target={node.attribs.target}
              rel={node.attribs.rel}
              className={styles.MemberProfileLink}
            >
              {node.children[0].data}
            </a>
          </HoverProvider>
        )
      }
    }
  }

  return(
    <div
      className={styles.MemberProfile}
    >
      {HTMLReactParser(props.text, options)}
    </div>
  )
}

export default MemberMaintext

import React from "react"
import * as styles from './style.module.sass'

import AboutIntroductionTitle from "../../atoms/AboutIntroductionTitle";
import AboutIntroductionBody from "../../atoms/AboutIntroductionBody";

const AboutIntroduction = (props) => {
  return(
    <>
      <div className={styles.aboutIntroductionTitle}>
        <AboutIntroductionTitle
          layoutName={props.layoutName}
          title={props.introduction.introductionTitle} />
      </div>
      <div className={styles.aboutIntroductionBody}>
        <AboutIntroductionBody
          layoutName={props.layoutName}
          body={props.introduction.introductionBody} />
      </div>
    </>
  )
};

export default AboutIntroduction

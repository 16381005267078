import React from "react"
import * as styles from './style.module.sass'

import ContactPlainText from "../../atoms/ContactPlainText"
import ContactAddress from "../../atoms/ContactAddress"
import ContactInformationList from "../../molecules/ContactInformationList"

const ContactBody = (props) => {
  return(
    <div className={styles.ContactBody}>
      <div className={styles.ContactBodyItem}>
        {
          props.isAbout && (<p className={styles.ContactBodyItemTitle}>Company</p>)
        }
        <ContactPlainText
          layoutName={props.layoutName}
          text={props.contactInformation.commonCorporateName}
        />
      </div>
      <div className={styles.ContactBodyItem}>
        {
          props.isAbout && (<p className={styles.ContactBodyItemTitle}>Address</p>)
        }
        <ContactAddress
          layoutName={props.layoutName}
          address={props.contactInformation.commonAddress}
          googleMapsLink={props.contactInformation.commonGoogleMapUrl}
          isAbout={props.isAbout}
        />
      </div>
      <ContactInformationList
        layoutName={props.layoutName}
        contactInformation={props.contactInformation}
        isAbout={props.isAbout}
      />
    </div>
  )
}

export default ContactBody

import React from "react"
import * as styles from './style.module.sass'

import HoverProvider from '../../atoms/HoverProvider'
import SingleImage from '../../atoms/SingleImage'
import PlaceholderImage from "../../atoms/PlaceholderImage"

const MemberItem = (props) => (
  <HoverProvider
      hoverClassName={styles.isHover}
  >
    <button
      onClick={() => {props.clickEvent()}}
      className={styles.MemberItem}
    >
      <div className={styles.MemberItemImage}>
        <div className={
          props.imageHover? styles.MemberItemImageBody : styles.MemberItemImageBody + ' ' + styles.MemberItemImageBodyOnlyOpacity
        }>
          {
            props.image?(
              <SingleImage
                layoutName={props.layoutName}
                source={props.image.localFile}
                absolute={true}
                alt={props.name}
                sizes={"(max-width: 449px) 454px, 1035px"}
                lazy={true}
              />
            ):(
              <PlaceholderImage
                layoutName={props.layoutName}
                absolute={true}
                width={'42.6%'}
              />
            )
          }
        </div>
        { props.image && props.imageHover && (
          <div className={styles.MemberItemImageHover}>
            <SingleImage
              layoutName={props.layoutName}
              source={props.imageHover.localFile}
              absolute={true}
              alt={props.name}
              sizes={"(max-width: 449px) 454px, 1035px"}
              lazy={true}
            />
          </div>
        )}
      </div>
      <h3
        className={styles.MemberItemName}
      >
        {props.name}
      </h3>
    </button>
  </HoverProvider>
);

export default MemberItem

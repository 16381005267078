import React from "react"
import * as styles from './style.module.sass'

const AwardSingle = (props) => (
  <>
    <p className={styles.AwardSingleYear}>
      {props.year}
    </p>
    <p className={styles.AwardSingleName}>
      {props.awardName}
    </p>
  </>
)

export default AwardSingle

import React from 'react'
import * as styles from './style.module.sass'

const MemberName = (props) => (
  <h1 className={styles.MemberName}>
    <span>{props.name}</span>
    <span>{props.enName}</span>
  </h1>
)

export default MemberName

import React, {useState, useCallback, useRef} from "react"
import * as styles from './style.module.sass'

import AboutNomenaBaseGraph from "../../atoms/AboutNomenaBaseGraph"
import AboutNomenaBaseFloorList from "../../molecules/AboutNomenaBaseFloorList"
import AboutNomenaBaseImage from "../../atoms/AboutNomenaBaseImage"
import DownloadFile from "../../atoms/DownloadFile"

const AboutNomenBase = (props) => {
  const [hoveredNum, updateHoveredNum] = useState(-1);
  const isMobile = useRef(
    typeof window === 'undefined'? false :
    (/android|ipod|ipad|iphone|macintosh/.test(navigator.userAgent.toLowerCase()) && 'ontouchend' in document)?true : false
  );

  return(
    <div className={styles.AboutNomenBase}>
      <div className={styles.AboutNomenBaseContent}>
        <div className={styles.AboutNomenBaseContentGraph}>
          <AboutNomenaBaseGraph
            layoutName={props.layoutName}
            information={props.information}
            hoveredNum={hoveredNum}
            updateHoveredNum={useCallback((_index) => updateHoveredNum(_index), [hoveredNum])}
          />
        </div>
        <div className={styles.AboutNomenBaseContentText}>
          <AboutNomenaBaseFloorList
            layoutName={props.layoutName}
            information={props.information}
            hoveredNum={hoveredNum}
            updateHoveredNum={useCallback((_index) => updateHoveredNum(_index), [hoveredNum])}
          />
        </div>
        <div className={styles.AboutNomenBaseTextContent}>
          <p
            className={styles.AboutNomenBaseTextContentBody}
          >
            {props.information.aboutBaseIntroduction}
          </p>
          <div className={styles.AboutNomenBaseTextContentLink}>
            <DownloadFile
              layoutName={props.layoutName}
              fileUrl={props.information.aboutBasePdf.localFile.publicURL}
            />
          </div>
        </div>
        {!isMobile.current && (
          <AboutNomenaBaseImage
            layoutName={props.layoutName}
            information={props.information}
            hoveredNum={hoveredNum}
          />
        )}
      </div>
    </div>
  )
}

export default AboutNomenBase

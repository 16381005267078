import React, {useRef} from "react"
import * as styles from './style.module.sass'

import HoverProvider from '../HoverProvider'

const ContactInformationItem = (props) => {
  const isMobile = useRef(
    typeof window === 'undefined'? false :
    (/android|ipod|ipad|iphone|macintosh/.test(navigator.userAgent.toLowerCase()) && 'ontouchend' in document)?true : false
  )

  return(
    <>
      <h2
        className={
          props.isAbout? styles.ContactInformationItemTitle + ' ' + styles.ContactInformationItemTitleIsAbout : styles.ContactInformationItemTitle
        }
      >
        {props.section}
      </h2>
      <div className={styles.ContactInformationItemContent}>
        {
          props.isMail || isMobile.current && props.isTel?
            <HoverProvider
              hoverClassName={styles.isHover}
            >
              <a
                className={
                  props.isMail? styles.ContactInformationItemContentLink + ' ' + styles.ContactInformationItemContentLinkMail : styles.ContactInformationItemContentLink
                }
                href={
                  props.isMail? `mailto:${props.content}`
                  : `tel:${props.content}`
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                  {props.content}
              </a>
            </HoverProvider>
          :
            <p
              className={styles.ContactInformationItemContentText}
            >
              {props.content}
            </p>
        }
      </div>
    </>
  )
}

export default ContactInformationItem

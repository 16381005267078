// extracted by mini-css-extract-plugin
export var aboutAwards = "style-module--aboutAwards--a2e15";
export var aboutAwardsContent = "style-module--aboutAwardsContent--d1c6b";
export var aboutAwardsTitle = "style-module--aboutAwardsTitle--2c853";
export var aboutCompanyProfile = "style-module--aboutCompanyProfile--20d2a";
export var aboutCompanyProfileContent = "style-module--aboutCompanyProfileContent--e2bd9";
export var aboutCompanyProfileTitle = "style-module--aboutCompanyProfileTitle--911a2";
export var aboutInternship = "style-module--aboutInternship--65b1a";
export var aboutInternshipContent = "style-module--aboutInternshipContent--57b00";
export var aboutInternshipTitle = "style-module--aboutInternshipTitle--5fa37";
export var aboutIntroduction = "style-module--aboutIntroduction--327cf";
export var aboutMemberList = "style-module--aboutMemberList--49aed";
export var aboutNomenaBase = "style-module--aboutNomenaBase--69a28";
export var container = "style-module--container--1340c";
export var containerWrap = "style-module--containerWrap--32b5a";
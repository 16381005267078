import React from "react"
import * as styles from './style.module.sass'

const AboutIntroductionTitle = (props) => (
  <h1
    className={styles.aboutIntroductionTitle}
    dangerouslySetInnerHTML={{ __html: props.title.replace(/\s/g, '')}}
  >
  </h1>
);

export default AboutIntroductionTitle

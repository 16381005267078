import React from "react"
import { Link } from "gatsby"
import * as styles from './style.module.sass'

import HoverProvider from '../HoverProvider'

const BackButton = (props) => (
  <HoverProvider
    hoverClassName={styles.BackButtonIsHover}
  >
    <button
      onClick={() => props.modalCloseMethod()}
      className={styles.BackButton}
    >
      Back
    </button>
  </HoverProvider>
)

export default BackButton

import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/organisms/seo"

import AboutTemplate from "../components/template/AboutTemplate"

const AboutPage = ({ data }) => {
  return (
    <>
      <Seo title="About" />
      <AboutTemplate
        data={data}
      />
    </>
  )
}
export default AboutPage

export const pageQuery = graphql`
  query {

    aboutQuery: wpPage(slug: {eq: "about"}) {
      aboutIntroduction {
        introductionTitle
        introductionBody
      }
      aboutBase {
        aboutBase1f {
          use
          photos{
            photo{
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    breakpoints: [270, 812]
                    quality: 100
                    width: 812
                  )
                }
              }
            }
          }
        }
        aboutBase2f {
          use
          photos{
            photo{
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    breakpoints: [270, 812]
                    quality: 100
                    width: 812
                  )
                }
              }
            }
          }
        }
        aboutBase3f {
          use
          photos{
            photo{
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    breakpoints: [270, 812]
                    quality: 100
                    width: 812
                  )
                }
              }
            }
          }
        }
        aboutBase4f {
          use
          photos{
            photo{
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    breakpoints: [270, 812]
                    quality: 100
                    width: 812
                  )
                }
              }
            }
          }
        }
        aboutBaseRoof {
          use
          photos{
            photo{
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    breakpoints: [270, 812]
                    quality: 100
                    width: 812
                  )
                }
              }
            }
          }
        }
        aboutBaseIntroduction
        aboutBasePdf {
          localFile {
            publicURL
          }
        }
      }
      aboutAward {
        aboutAwardlist {
          year
          awardName
        }
      }
    }

    memberQuery: allWpMember(sort: {fields: date, order: ASC}) {
      edges {
        node {
          title
          slug
          MemberProfile {
            memberEnName
            memberRoll
            memberRollEn
            memberProfile
            memberCareer
            memberImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    breakpoints: [454,1035]
                    quality: 100
                    width: 1035
                  )
                }
              }
            }
            memberImageHover {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    breakpoints: [454,1035]
                    quality: 100
                    width: 1035
                  )
                }
              }
            }
          }
        }
        previous {
          slug
          title
        }
        next {
          slug
          title
        }
      }
    }

    internshipQuery: allWpInternship(sort: {fields: date, order: ASC}) {
      nodes {
        title
        internshipContent {
          startTime
          rollLis {
            roll
          }
        }
      }
    }

    optionQuery: allWp {
      edges {
        node {
          themeOptions {
            contactInformation {
              commonAddress
              commonCorporateName
              commonEmailAddress
              commonFaxNumber
              commonFoundDate
              commonFounder
              commonGoogleMapUrl
              commonPhoneNum
            }
          }
        }
      }
    }

  }
`

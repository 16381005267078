import React, {useRef} from "react"
import { Link } from "gatsby"
import * as styles from './style.module.sass'

import HoverProvider from '../HoverProvider'
import ArrowSp from '../../../images/contact-arrow-sp.svg'
import ArrowPc from '../../../images/contact-arrow-pc.svg'

const ContactAddress = (props) => {
  const isMobile = useRef(
    typeof window === 'undefined'? false :
    (/android|ipod|ipad|iphone|macintosh/.test(navigator.userAgent.toLowerCase()) && 'ontouchend' in document)?true : false
  )

  if(isMobile.current) {
    return(
      <p className={styles.ContactAddress}>
        <span className={styles.ContactAddressItem + ' ' + styles.ContactAddressItemLinkWrap}>
          <HoverProvider
            hoverClassName={styles.isHover}
          >
            <a
              className={styles.ContactAddressItemLink}
              href={props.googleMapsLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>{props.address}</span>
            </a>
          </HoverProvider>
          <span className={styles.ContactAddressItemLinkIcon}>
            <img src={ArrowSp} />
          </span>
        </span>
      </p>
    )
  }else{
    return(
      <p className={
        props.isAbout? styles.ContactAddress + ' ' + styles.ContactAddressIsAbout
        : styles.ContactAddress
      }>
        <span className={styles.ContactAddressItem}>
          {props.address}
        </span>
        <span className={
          props.isAbout? styles.ContactAddressItem + ' ' + styles.ContactAddressItemIsAbout + ' ' + styles.ContactAddressItemSep : styles.ContactAddressItem + ' ' + styles.ContactAddressItemSep
        }></span>
        <span className={styles.ContactAddressItem + ' ' + styles.ContactAddressItemLinkWrap}>
          <HoverProvider
            hoverClassName={styles.isHover}
          >
            <a
              className={styles.ContactAddressItemLink}
              href={props.googleMapsLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>Google Map</span>
            </a>
          </HoverProvider>
          <span className={styles.ContactAddressItemLinkIcon}>
            <img src={ArrowPc} />
          </span>
        </span>
      </p>
    )
  }
}

export default ContactAddress

import React from 'react'
import * as styles from './style.module.sass'

const MemberRoll = (props) => (
  <p className={styles.MemberRoll}>
    <span>{props.roll}</span>
    <span></span>
    <span>{props.enRoll}</span>
  </p>
)

export default MemberRoll

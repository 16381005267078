// extracted by mini-css-extract-plugin
export var FvFooterBackground = "style-module--FvFooterBackground--2342e";
export var FvFooterBackgroundIsHide = "style-module--FvFooterBackgroundIsHide--d09d0";
export var FvFooterBackgroundShow = "style-module--FvFooterBackgroundShow--a1daa";
export var FvFooterLogo = "style-module--FvFooterLogo--ddd88";
export var FvFooterLogoIsDisable = "style-module--FvFooterLogoIsDisable--9f349";
export var FvFooterLogoIsHide = "style-module--FvFooterLogoIsHide--7830b";
export var FvFooterLogoShow = "style-module--FvFooterLogoShow--e8eab";
export var FvFooterNews = "style-module--FvFooterNews--5f69c";
export var FvFooterNewsIsDisable = "style-module--FvFooterNewsIsDisable--48720";
export var FvFooterNewsIsHide = "style-module--FvFooterNewsIsHide--1da49";
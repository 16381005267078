import React, { useEffect, useState, useRef } from "react"
import { memo } from "react"
import * as styles from './style.module.sass'

import SingleImage from "../SingleImage"

const AboutNomenaBaseSwitchImage= (props) => {
  const [imageNum, changeImageNum] = useState(0);
  const updateImageRef = useRef(() => {});
  const isMobile = useRef(
    typeof window === 'undefined'? false :
    (/android|ipod|ipad|iphone|macintosh/.test(navigator.userAgent.toLowerCase()) && 'ontouchend' in document)?true : false
  );

  const updateImage = () => {
    let nextImagNum = imageNum;
    nextImagNum = (nextImagNum+1 >= props.source.length)? 0 : nextImagNum + 1;
    changeImageNum(nextImagNum);
  }

  useEffect(() => {
    updateImageRef.current = updateImage
  }, [updateImage]);

  useEffect(() => {
    if(!props.active || props.source.length<=1) return;
    const switchImageInterval = setInterval(() => updateImageRef.current(), 1300)
    return () => clearInterval(switchImageInterval);
  }, [props.active]);

  return(
    <div className={styles.switchImageList}>
      {props.source.map((image, id) => (
        <div
          key={id}
          className={
            (id != imageNum)? styles.switchImageItem + ' ' + styles.switchImageItemIsHide : styles.switchImageItem
          }
        >
          <SingleImage
            layoutName={props.layoutName}
            source={image.photo.localFile}
            alt={''}
            sizes={"50vw"}
          />
        </div>
      ))}
    </div>
  )
};

export default AboutNomenaBaseSwitchImage

import React from "react"
import * as styles from './style.module.sass'

const AboutIntroductionBody = (props) => (
  <p
    className={styles.aboutIntroductionBody}
    dangerouslySetInnerHTML={{ __html: props.body }}
  >
  </p>
);

export default AboutIntroductionBody

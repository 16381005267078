import React from "react"
import * as styles from './style.module.sass'

import SingleImage from "../../atoms/SingleImage"
import MemberBodyText from "../../molecules/MemberBodyText"
import PlaceholderImage from "../../atoms/PlaceholderImage"

const MemberBody = (props) => {
  return(
    <article className={styles.MemberBody}>
      <div className={styles.MemberBodyImage}>
        <div className={styles.MemberBodyImageContainer}>
          {
            props.post.MemberProfile.memberImage?(
              <SingleImage
                layoutName={props.layoutName}
                absolute={true}
                source={props.post.MemberProfile.memberImage.localFile}
                alt={props.post.title}
                sizes={"(max-width: 900px) 454px, 1035px"}
              />
            ):(
              <PlaceholderImage
                layoutName={props.layoutName}
                absolute={true}
                width={'42.6%'}
              />
            )
          }
        </div>
      </div>
      <div className={styles.MemberBodyText}>
        <MemberBodyText
          layoutName={props.layoutName}
          post={props.post}
        />
      </div>
    </article>
  )
}

export default MemberBody

import React, {useRef, useLayoutEffect} from "react"
import * as styles from './style.module.sass'

const SpPageTitle = (props) => {
  const isMobile = useRef(
    typeof window === 'undefined'? false :
    (/android|ipod|ipad|iphone|macintosh/.test(navigator.userAgent.toLowerCase()) && 'ontouchend' in document)? true : false
  ),
  containerDom = useRef(typeof window === 'undefined'? null : document.getElementById('container')),
  scrollTarget = useRef(typeof window === 'undefined'? null : props.layoutName=='/'&&containerDom.current!=undefined&&isMobile.current? containerDom.current : window),
  documentTarget = useRef(typeof window === 'undefined'? null : props.layoutName=='/'&&containerDom.current!=undefined&&isMobile.current? containerDom.current : document.documentElement),
  scrollPosBefore = useRef(0),
  ticking = useRef(false),
  target = useRef(null);

  const updateStatus = (_scrollTop) => {
    if(target.current==null) return;
    if((_scrollTop < scrollPosBefore.current || _scrollTop < 10)&&target.current.classList.contains(styles.SpPageTitleIsScrollHide)) {
      target.current.classList.remove(styles.SpPageTitleIsScrollHide);
    }else if(_scrollTop > scrollPosBefore.current && _scrollTop >= 10 &&!target.current.classList.contains(styles.SpPageTitleIsScrollHide)){
      target.current.classList.add(styles.SpPageTitleIsScrollHide);
    }
  }

  const scrollEventTicking = () => {
    if( !ticking.current ) {
      window.requestAnimationFrame( () => {
        const scrollTop = scrollTarget.current.pageYOffset || documentTarget.current.scrollTop;
        updateStatus(scrollTop);
        scrollPosBefore.current = scrollTop;
        ticking.current = false;
      });
      ticking.current = true;
    }
  }

  useLayoutEffect(() => {
    if(typeof window === 'undefined'  || props.layoutName=='/projects/') return;
    containerDom.current = document.getElementById('container');
    scrollTarget.current = typeof window === 'undefined'? null : props.layoutName=='/'&&containerDom.current!=undefined&&isMobile.current? containerDom.current : window;
    documentTarget.current = typeof window === 'undefined'? null : props.layoutName=='/'&&containerDom.current!=undefined&&isMobile.current? containerDom.current : document.documentElement;
    if(scrollTarget.current == null) return;
    ticking.current = false;
    scrollPosBefore.current = scrollTarget.current.pageYOffset || documentTarget.current.scrollTop;
    scrollTarget.current.addEventListener(`scroll`, scrollEventTicking, { passive: true });
    return () => {
      ticking.current = false;
      scrollTarget.current.removeEventListener(`scroll`, scrollEventTicking, { passive: true })
    }
  }, [scrollTarget.current])

  return (
    <h1
      className={styles.SpPageTitle}
      ref={target}
    >
      {props.title}
    </h1>
  )
}

export default SpPageTitle
